import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
// import Badge from "react-bootstrap/Badge"
// import { RiShoppingCartLine } from "react-icons/ri"

import FormConnect from "../global/FormConnect"
// import ProductCard from "../shop/ProductCard"

const ModalTV = ({
  props,
  productFluidImage,
  productTitle,
  productPrice,
  pageSlug,
  pageTitle,
  provider,
}) => {
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)

  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulAsset(id: { eq: "dcf9e483-b82e-5df6-9c6c-e050baf5d3a3" }) {
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Button
              className="tvtariff-card-button"
              onClick={handleToggle}
              variant="outline-newtele"
              block
            >
              Підключити
            </Button>

            <Modal
              {...props}
              show={show}
              onHide={() => setShow(false)}
              aria-labelledby="tvModal"
              className="clearfix mb-0"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contactModal">
                  Введіть своє ім'я та номер телефону
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col lg={7} md={12}>
                      <FormConnect
                        pageSlug={`${pageSlug}#buy`}
                        pageTitle={pageTitle}
                        productTitle={`${provider} - ${productTitle}`}
                        productPrice={productPrice}
                        formType="tv"
                      />
                    </Col>
                    <Col
                      lg={5}
                      md={12}
                      className="d-flex align-items-center justify-content-center text-center"
                    >
                      <div className="">
                        <Img
                          className="shop-modal-product-image"
                          fluid={productFluidImage}
                          alt={productTitle}
                        />
                        <div className="h5">{productTitle}</div>
                        <div>{productPrice} /міс</div>
                      </div>
                      {/* <Badge variant="light">{productPrice}</Badge> */}
                      {/* <ProductCard
                        productTitle={productTitle}
                        productPrice={productPrice}
                        productImage={productImage}
                      /> */}
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal>
          </>
        )
      }}
    />
  )
}

export default ModalTV
