import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Card from "react-bootstrap/Card"
// import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// import ModalChannelsList from "../global/ModalTvChannel"

// import Button from "react-bootstrap/Button"
// import Badge from "react-bootstrap/Badge"

// import { GiStrawberry, GiTvRemote } from "react-icons/gi"'
// import { BiFootball, BiCameraMovie, BiMovie } from "react-icons/bi"
import { GiStrawberry } from "react-icons/gi"
import { IoIosCheckmarkCircleOutline } from "react-icons/io"
import { BiFootball } from "react-icons/bi"

import TvModal from "../../components/global/ModalTelevision"

const TvTariffCard = ({
  tariffImage,
  tariffTitle,
  tariffDescription,
  tariffPrice,
  tariffChannels,
  tariffChannelsHD,
  numberTvChannels,
  numberSerials,
  numberFilms,
  features,
  numberDevices,
  provider,
  providerSlug,
  providerTarifSlug,
  ChannelsCategoryList,
}) => (
  <Col lg={4} md={4} sm={12} className="mb-3">
    <Card className="tv-tariff-card mb-3 h-100">
      <Img
        fluid={tariffImage}
        className="img-responsive m-3"
        alt="cardimage fluid"
      />
      {/* <Card.ImgOverlay className="tariff-image-overlay"></Card.ImgOverlay> */}
      <Card.Body className="tv-tariff-card-body">
        <Card.Title className="gradient-text text-uppercase">
          {tariffTitle}
        </Card.Title>
        <Card.Text as="div" className="h-100">
          <span className="tariff-price">{tariffPrice}</span>
          <span className="tariff-price-currency">грн/міс</span>
          {/* <div className="tv-tariff-card-description my-3">
          {tariffDescription}
        </div> */}
          <ul className="list-unstyled tariff-list mt-2 tv-tariff-card-list">
            {/* <li>
            <IoIosCheckmarkCircleOutline className="tariff-list-icon" />
            Каналів {tariffChannels}
          </li> */}
            {numberTvChannels && (
              <li className="w-100">
                <IoIosCheckmarkCircleOutline className="tvtariff-list-icon" />
                Каналів{" "}
                <span className="tv-tariffcard-value gradient-text">
                  {tariffChannels.length}
                </span>
                {/* <ModalChannelsList
                  Channels={tariffChannels}
                  Total={tariffChannels.length}
                  TariffName={tariffTitle}
                  ChannelsCategoryList={ChannelsCategoryList}
                /> */}
              </li>
            )}
            {tariffChannelsHD && (
              <li className="w-100">
                <IoIosCheckmarkCircleOutline className="tvtariff-list-icon" />
                Каналів HD{" "}
                <span className="tv-tariffcard-value gradient-text">
                  {tariffChannelsHD}
                </span>
              </li>
            )}

            {numberFilms ? (
              <li>
                <IoIosCheckmarkCircleOutline className="tariff-list-icon" />
                Кінофільмів{" "}
                <span className="tv-tariffcard-value gradient-text">
                  {numberFilms}
                </span>
              </li>
            ) : (
              ``
            )}
            {numberSerials ? (
              <li>
                <IoIosCheckmarkCircleOutline className="tariff-list-icon" />
                Серіалів{" "}
                <span className="tv-tariffcard-value gradient-text">
                  {numberSerials}
                </span>
              </li>
            ) : (
              ``
            )}
            <li>
              <IoIosCheckmarkCircleOutline className="tariff-list-icon" />
              Кількість пристроїв{" "}
              <span className="tv-tariffcard-value gradient-text">
                {numberDevices}
              </span>
            </li>
            {features
              ? features.map((f, i) =>
                  f.title === "Футбол" ? (
                    <li key={i}>
                      <BiFootball className="tariff-list-icon" />
                      Канали з футболом
                    </li>
                  ) : f.title === "18+" ? (
                    <li key={i}>
                      <GiStrawberry className="tariff-list-icon" />
                      Канали 18+
                    </li>
                  ) : (
                    <li key={i}>
                      <IoIosCheckmarkCircleOutline className="tariff-list-icon" />
                      Невідомо
                    </li>
                  )
                )
              : ``}
          </ul>
        </Card.Text>

        <Card.Text className="text-center">
          <Link to={`/telebachennya/${providerSlug}/${providerTarifSlug}/`}>
            Перелік каналів
          </Link>
        </Card.Text>

        <TvModal
          productFluidImage={tariffImage}
          provider={provider}
          productTitle={`Тариф ${tariffTitle}`}
          productPrice={tariffPrice}
          pageSlug={``}
          pageTitle={``}
        />
      </Card.Body>
    </Card>
  </Col>
)

TvTariffCard.defaultProps = {
  tariffImage: ``,
  tariffTitle: `Назва`,
  tariffDescription: `no`,
  tariffPrice: `0`,
  tariffChannels: `0`,
  tariffChannelsHD: `0`,
  numberTvChannels: `0`,
  numberSerials: `0`,
  numberFilms: `0`,
  features: ``,
  numberDevices: `4`,
  provider: `no`,
  ChannelsCategory: `Розважальні`,
}

export default TvTariffCard
