import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
// import BackgroundImage from "gatsby-background-image"

// import { BLOCKS } from "@contentful/rich-text-types"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ContentfulReachContent from "../components/global/ContentfulReachContent"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"
import TariffCard from "../components/tv/TelevisionTariffCard"
// import ChannelCard from "../components/tv/ChanelCard"

// Import React Bootstrap
import Container from "react-bootstrap/Container"
// import CardDeck from "react-bootstrap/CardDeck"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export const query = graphql`
  query($slug: String!) {
    channelstypes: allContentfulTvChannelCategory(
      filter: { node_locale: { eq: "uk" } }
    ) {
      nodes {
        id
        category
      }
    }
    provider: contentfulTvProvider(
      node_locale: { eq: "uk" }
      seo: { slug: { eq: $slug } }
    ) {
      id
      name
      devices
      url
      seo {
        title
        metaDescription
        slug
        pageImage {
          fluid(maxWidth: 620) {
            src
          }
        }
      }
      logo {
        fixed(width: 300, quality: 95) {
          ...GatsbyContentfulFixed_withWebp
        }
        fluid(maxHeight: 400, resizingBehavior: PAD) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      childContentfulTvProviderInstructionRichTextNode {
        json
      }
      faq {
        id
        faqQuestion
        faqAnswer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    tariffs: allContentfulTvTariff(
      filter: {
        isActive: { eq: true }
        provider: { seo: { slug: { eq: $slug }, node_locale: { eq: "uk" } } }
      }
      sort: { fields: price, order: ASC }
    ) {
      nodes {
        id
        title
        price
        numberTvChannels
        numberSerials
        numberFilms
        isActive
        seo {
          slug
        }
        features {
          title
          id
        }
        description {
          description
        }
        channels {
          id
          channelName
          isHd
          category {
            category
          }
          channelLogo {
            fluid(
              maxWidth: 128
              maxHeight: 80
              quality: 85
              resizingBehavior: PAD
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const TvproviderTemplate = ({ data: { provider, tariffs, channelstypes } }) => {
  // const backgroundFluidLinearGradient = [
  //   cottage.fluid,
  //   `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.5), rgb(15, 1, 94, 0.8))`,
  // ].reverse()

  return (
    <Layout>
      <SEO
        title={`Телебачення від ${provider.seo.title}`}
        description={provider.seo.metaDescription}
        image={`https:${provider.seo.pageImage.fluid.src}`}
        url={`/telebachennya/${provider.seo.slug}/`}
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/telebachennya/">Телебачення</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {provider.name}
              </li>
            </ol>
          </nav>
          <Row>
            <Col lg={8} md={6} sm={12} className="tv-hero">
              <h1 className="gradient-text mt-auto">
                телебачення {provider.name}
              </h1>
              <div className="hero-slogan mb-5">
                Ціни на Інтернет-телебачення від {provider.name} та інструкція з
                налаштування для абонентів Нового Телекому
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Img fixed={provider.logo.fixed} alt={`лого ${provider.name}`} />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="my-5">
        <Row>
          {tariffs.nodes.map(tariff => (
            <TariffCard
              key={tariff.id}
              tariffTitle={tariff.title}
              tariffPrice={tariff.price}
              // tariffDescription={tariff.description.description}
              tariffImage={provider.logo.fluid}
              tariffChannels={tariff.channels}
              // tariffChannels={tariff.channels ? tariff.channels.length : ``}
              tariffChannelsHD={
                tariff.channels &&
                tariff.channels.filter(item => item.isHd).length
              }
              numberTvChannels={tariff.numberTvChannels}
              numberSerials={tariff.numberSerials}
              numberFilms={tariff.numberFilms}
              features={tariff.features}
              numberDevices={provider.devices}
              provider={provider.name}
              providerSlug={provider.seo.slug}
              providerTarifSlug={tariff.seo.slug}
              ChannelsCategoryList={channelstypes}
            />
          ))}
        </Row>
      </Container>
      <Container className="my-5 contentful-reachformats">
        <ContentfulReachContent
          contentJSON={
            provider.childContentfulTvProviderInstructionRichTextNode.json
          }
        />
      </Container>
      <Container className="my-5">
        <div className="h3">Часті запитання (FAQ)</div>
        <p>
          Надсилайте питання про умови підключення або налаштування телебачення
          на support@newtele.com.ua.
        </p>
        {provider.faq.map(faq => (
          <span key={faq.id}>
            <h4>{faq.faqQuestion}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: faq.faqAnswer.childMarkdownRemark.html,
              }}
            />
          </span>
        ))}
      </Container>
    </Layout>
  )
}

export default TvproviderTemplate
